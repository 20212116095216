@use "../../assets/styles/colors";

button {
    background-color: colors.$black;
    color: colors.$white;
    border: none;
    border-radius: 80px;
    padding: 16px 24px;
    margin: 24px 0px;
    cursor: pointer;
    width: max-content;
}

@media (max-width:480px) {
    button {
        width: 100%;
        text-align: center;
    }
}