@use "../../assets/styles/colors";

.form {
    padding: 48px;
    width: 600px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    h3 {
        margin-top: 40px;
    }
    p {
        color: colors.$black-50;
        margin-top: 16px;
    }
    form {
        margin-top: 48px;
        width: 380px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        input {
            height: 48px;
            outline: 0;
            border-width: 0 0 1px;
            border-color: colors.$black-12;
        }
        input:focus {
          border-color: colors.$black-24;
        }
        textarea {
          height: 100px;
          outline: 0;
          border-width: 0 0 1px;
          border-color: colors.$black-12;
        }
        textarea:focus {
          border-color: colors.$black-24;
        }

        button {
          font-size: 16px;
        }
    }

    .tag--header {
      margin-bottom: 8px;
    }

    .tag-container {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    .tag-radio {
      display: none;
    }

    .tag-label {
      padding: 8px 16px;
      background-color: colors.$black-8;
      border-radius: 8px;
      color: colors.$black;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;
    }

    .tag-radio:checked + .tag-label {
      background-color: colors.$black;
      color: colors.$white;
    }

}

@media (max-width:480px) {

  .form {
    width: 100%; 
    padding: 16px;
    margin: 0;
    box-sizing: border-box; 
    h3 {
      font-size: 32px;
      line-height: 40px;
      width: 100%;
    }
    p{
      width: 100%;
    }
    .inputs {
      form {
        width: 100%;
        box-sizing: border-box; 
      }
    }
  }
}
