/* tokens for colors */

$black: #000;
$black-50: rgba(0, 0, 0, 0.5);
$black-24: rgba(0, 0, 0, 0.24);
$black-12: rgba(0, 0, 0, 0.12);
$black-8: rgba(0, 0, 0, 0.08);


$white: #fff;
$white-50: rgba(255, 255, 255, 0.5);
$white-32: rgba( 255, 255, 255, 0.32);
$white-16: rgba( 255, 255, 255, 0.16);

$telegram-color: #2AABEE;
$whatsapp-color: #28D146;