@use "../../assets/styles/colors.scss";


.secondary--button {
    display: block;
    width: max-content;
    padding: 12px 16px;
    border-radius: 80px;
    font-size: 12px;
    background-color: colors.$black-8;
    transition: all ease 0.3s;
    color: colors.$black;
    text-decoration: none;
    margin-top:32px;
    letter-spacing: -0.5px;
    cursor: pointer;
    span {
        margin-left: 4px;
    }
    &:hover {
        background-color: colors.$black-12;
    }
}

.secText {
    color: colors.$black-50;
}

@media (max-width:480px) {
    .secondary--button {
        width: 100%;
        text-align: center;
    }
}