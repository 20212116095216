@use "../../assets/styles/colors.scss";


.main {
    display: block;
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    .left--side {
        padding: 48px;
        width: 420px;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        .logo {
            margin-bottom: 40px;
        }
        h2 {
            margin-top: 16px;
        }
        p {
            color: colors.$black-50;
        }
        .bottom {
            .caption--text {
                width: 240px;
            }
            a {
                background: none;
                padding: 0;
                display:inline;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: -0.5px;
                .telegram-text {
                    color: colors.$telegram-color;
                }
            
                .whatsapp-text {
                    color: colors.$whatsapp-color;
                }
                &:hover {
                    background: none;
                }
            }
        }
    }
    .right--side {
        display: flex;
        justify-content: flex-start;
    }
}

@media (max-width:480px) {
    .main {
        display: block;
        .left--side {
            padding: 16px;
            width: auto;
            height: auto;
        }
        .upper {
            margin-bottom: 40px;
        }
        .right--side {
            display: none;
        }
    }
}