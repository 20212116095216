@use "./colors";

@font-face {
    font-family: 'SuisseIntl';
    src: url('/assets/fonts/SuisseIntl-Book.woff2') format('woff2'),
         url('/assets/fonts/SuisseIntl-Book.woff') format('woff');
}

$suisseintl: 'SuisseIntl';

h1, h2, h3, h4, h5, h6, p, a, .telegram-text, .whatsapp-text, input, textarea, button, .tag-label {
    font-family: 'SuisseIntl';
    font-weight: normal;
    margin: 0;
    padding: 0;
}

@mixin h2 {
    font-size: 16px;
    line-height: 20px;
    letter-spacing:-0.5px;
    color: colors.$black;
}

@mixin h3 {
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -1px;
    color: colors.$black;
}

@mixin h4 {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.5px;
    color: colors.$black;
}

@mixin p {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.5px;
    color: colors.$black;
}

@mixin input {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
}

@mixin textarea {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
}

@mixin a {
    text-decoration: none;
    color: colors.$black;
}

h2 {
    @include h2;
}

h3 {
    @include h3;
}

h4 {
    @include h4;
}

p {
    @include p;
}

input {
    @include input;
}

textarea {
    @include textarea;
}

a {
    @include a;
}

button {
    font-size: 12px;
    letter-spacing: -0.2px;
    line-height: 14px;
}